import React from 'react';
import { GetStaticProps, NextPage } from 'next';

import NotFound from '@common-lib/components/Error404';
import Head from 'next/head';
import PageWrapper from '@web/components/layout/PageWrapper';
import { useRouter } from 'next/router';
import Box from '@ui/components/Box';
import Text from '@ui/components/Text';
import Button from '@ui/components/Button';

const Error404: NextPage = () => {
	const { back } = useRouter();
	const handleClick = React.useCallback(() => {
		back();
	}, [back]);
	return (
		<>
			<Head>
				<title>Semina: Não encontrado</title>
			</Head>
			<PageWrapper usePx showMenu>
				<NotFound overlay={false} />
				<Box
					px={2}
					display="flex"
					flexDirection="row"
					justifyContent="center"
					textAlign="center"
				>
					<Box
						display="flex"
						width="100%"
						flexDirection="row"
						justifyContent="center"
						pt={10}
					>
						<Button
							onClick={handleClick}
							fullWidth
							size="medium"
							sx={{ maxWidth: 300 }}
						>
							Voltar
						</Button>
					</Box>
				</Box>
			</PageWrapper>
		</>
	);
};

export const getStaticProps: GetStaticProps = () => {
	return {
		props: {
			statusCode: 404,
		},
	};
};

export default Error404;
